import debounce from 'lodash/debounce'

export default {
  data: () => ({
    windowWidth: 0,
    screenOrientation: null
  }),

  beforeDestroy() {
    window.removeEventListener('resize', this.recalculateWidth, false)
  },

  created() {
    if (process.client) this.windowWidth = window.innerWidth
  },
  // init recalculate computed properties on window resize
  mounted() {
    this.windowWidth = window.innerWidth
    if (window.screen && window.screen.orientation) {
      window.screen.orientation.onchange = this.recalculateWidth
    }
    window.addEventListener('resize', this.recalculateWidth, false)
  },

  computed: {
    // function to detect mobile device by window width
    isMobile() {
      return this.windowWidth ? this.windowWidth <= 767 : this.$device.isMobile
    },

    // function to detect tablet device by window width
    isTablet() {
      return this.windowWidth
        ? this.windowWidth > 767 && this.windowWidth <= 1024
        : this.$device.isTablet
    },

    // function to detect desktop device by window width
    isDesktop() {
      return this.windowWidth ? this.windowWidth > 1024 : this.$device.isDesktop
    },

    isTouch() {
      return this.isMobile || this.isTablet
    },

    isDesktopOrTablet() {
      return this.isDesktop || this.isTablet
    }
  },

  methods: {
    recalculateWidth: debounce(function () {
      this.windowWidth = window.innerWidth
    }, 10)
  }
}
