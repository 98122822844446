<template lang="pug">
  .video-wrapper.is-flex(v-observe-visibility="{callback: initVideoPlayer, once: true}", ref="wrapper")
    video(ref="video", :poster="configuration.poster", :style="style", :autoplay="autoplay", :muted="muted", :loop="loop", :controls="controls")
</template>

<script>
export default {
  name: 'VideoPlayerComponent',

  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    style: {},
    player: null,
    defaultOptions: {
      debug: false,
      testBandwidth: true,
      startFragPrefetch: true,
      startLevel: -1,
      maxLoadingDelay: 3,
      capLevelToPlayerSize: true,
      poster: ''
    }
  }),

  computed: {
    configuration() {
      return {
        ...this.defaultOptions,
        ...this.options
      }
    },

    loop() {
      return !!this.options.loop
    },

    muted() {
      return !!this.options.muted
    },

    controls() {
      return !!this.options.controls
    },

    autoplay() {
      return !!this.options.autoplay
    },

    source() {
      return this.options.source
    }
  },

  async mounted() {
    if (!this.source) return
    await this.destroyOldPiPVideos()
    this.setVideoDimensions()
    this.$refs.video.addEventListener('canplay', this.canPlay, { once: true })
  },

  beforeDestroy() {
    const video = document.pictureInPictureElement
    if (!video && this.player) {
      this.player.destroy()
      return
    }
    if (video) this.addDestroyEvents()
  },

  methods: {
    addDestroyEvents() {
      this.$refs.video.addEventListener('pause', this.onPause, {
        once: true
      })

      this.$refs.video.addEventListener(
        'leavepictureinpicture',
        this.onLeavePiPMode,
        {
          once: true
        }
      )
    },

    removeDestroyEvents(video) {
      video.removeEventListener('pause', this.onPause, {
        once: true
      })

      video.removeEventListener('leavepictureinpicture', this.onLeavePiPMode, {
        once: true
      })
    },

    onPause() {
      const video = document.pictureInPictureElement
      setTimeout(() => {
        video.play()
      }, 110)
    },

    onLeavePiPMode(e) {
      const video = e.target
      if (video) {
        video.pause()
        video.removeAttribute('src') // empty source
        video.load()
      }
      this.removeDestroyEvents(video)
    },

    canPlay() {
      if (this.$refs.video && this.autoplay) this.$refs.video.currentTime += 0.1
      this.$emit('video-loaded')
    },

    setVideoDimensions() {
      let { width, height } = this.options
      const rate = height / width

      const maxWidth = this.$refs.wrapper.offsetWidth
      height = width * rate + 'px'
      width = (width > maxWidth ? maxWidth : width) + 'px'

      this.style = { width, height }
    },

    initVideoPlayer(isVisible) {
      if (isVisible)
        this.player = this.$hls.insert(
          this.$refs.video,
          this.source,
          this.configuration,
          null,
          this.playerFallback
        )
    },

    playerFallback() {
      const source = document.createElement('source')
      source.setAttribute('src', this.source)
      this.$refs.video.appendChild(source)
      this.$refs.video.play()
    },

    async destroyOldPiPVideos() {
      const video = document.pictureInPictureElement
      if (video) {
        video.pause()
        video.removeAttribute('src') // empty source
        video.load()
        await document.exitPictureInPicture()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.video-wrapper
  width: 100%
  justify-content: center
</style>
