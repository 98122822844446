<template lang="pug">
  section.mining.lazy(data-lazy-function="loaded", v-observe-visibility="{callback: initSwiper, once: true, intersection: { rootMargin: '100px' }}")
    v-image.mining__poster.lazy.is-hidden-mobile(v-if="showPoster", data-lazy-function="loaded", src="homePage/mining/bg-poster@2x.jpg", alt="Poster")
    video-player-component.mining__bg-video.bg-video-desktop.lazy.is-hidden-mobile(data-lazy-function="loaded", ref="backgroundVideo", :options="backgroundVideo", @video-loaded="videoBgLoaded")
    .container.is-flex
      .mining-video
        v-image.lazy.mining__poster.is-hidden-tablet.is-hidden-widescreen(v-if="showPoster", data-lazy-function="loaded", src="homePage/mining/bg-poster@2x.jpg", alt="Poster")
        video-player-component.bg-video-mobile.lazy.is-hidden-tablet.is-hidden-widescreen(data-lazy-function="loaded", ref="backgroundVideoMobile", :options="backgroundVideo", @video-loaded="videoBgLoaded")
      .mining-slider
        .sliders-block.mining__sliders-block.is-flex
          .mining__info
            h2.mining__title.is-italic {{ $t('homePage.videoGallery.title') }}
            .videoGallery__text {{ $t('homePage.videoGallery.text') }}
          .mining__wrapper-container
            .swiper-container.swiper-container__main(ref="mining-slider")
              .swiper-wrapper
                .swiper-slide(@click="onSlideClick(index)", v-for="(video, index) in videos", :key="video.guid", :data-swiper-slide-index="index", :class="{'slide-active': selectedIndex === index}")
                  .mining-content
                    .mining-content__image
                      v-image(:src="video.image.micro", :alt="video.alt", @click.native="onVideoPlayHandler(index)")
                      .mining__button-play.no-events
                        v-image(alt="Video player button", src="packaging/playButton@2x.png")
                    .mining-info-text.mining-content__text {{video.text}}
            .swiper-button-next
              icon(iconName="arrowRight", icon-color="#000000")
            .swiper-button-prev
              icon(iconName="arrowRight", icon-color="#000000")
          v-link.mining__learn-more.button.primary-color.light(:to="{ name: 'education-index-category', params: { category: 'mining-locations' }, query: { type: 'list'} }")
            | {{ $t('homePage.videoGallery.learnMore') }}
            span.sr-only {{ $t('homePage.videoGallery.learnMoreSR') }}
      video-modal(v-if="showVideoModal", :name="modalName", :options="modalOptions")
</template>

<script>
import VideoPlayerComponent from '~~/components/Common/VideoPlayer'
import deviceDetect from '~~/mixins/deviceDetect'

const width = 925
const ratio = 0.56349

export default {
  name: 'MiningVideoComponent',

  components: {
    VideoPlayerComponent,
    VideoModal: () => import('~~/components/Common/VideoModal')
  },

  mixins: [deviceDetect],

  data: () => ({
    swiper: null,
    modalName: 'mining-video',
    selectedIndex: 0,
    showVideoModal: false,
    showGalleryLightbox: false,
    showPoster: true
  }),

  computed: {
    backgroundVideo() {
      return {
        source:
          'https://video.thenaturalsapphirecompany.com/manual-created/ngc/mining/hls/output.m3u8',
        loop: true,
        autoplay: true,
        muted: true,
        controls: false
      }
    },

    videos() {
      return [
        {
          guid: '6cd6c8a8-dd5e-4851-bf6f-5813c5554ea3',
          source:
            'https://dylmsnce3s2r5.cloudfront.net/627a72f2-0590-4f89-a70c-177acf35a955/hls/river-mining.m3u8',
          image: {
            micro: 'homePage/mining/video-1@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('homePage.videoGallery.slides.0.alt'),
          text: this.$t('homePage.videoGallery.slides.0.text')
        },
        {
          guid: '63b95a02-d2ff-4bcb-95e2-c7c5e3ff7304',
          source:
            'https://dylmsnce3s2r5.cloudfront.net/ce330287-af21-451e-b953-7346e9fd2a42/hls/shaft-mining.m3u8',
          image: {
            micro: 'homePage/mining/video-2@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('homePage.videoGallery.slides.1.alt'),
          text: this.$t('homePage.videoGallery.slides.1.text')
        },
        {
          guid: '17adbe82-c0e8-4894-9541-ba9f14c9c017',
          source:
            'https://dylmsnce3s2r5.cloudfront.net/cc91d2f7-1169-407b-b5f6-72553a985dd1/hls/market-place.m3u8',
          image: {
            micro: 'homePage/mining/video-3@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('homePage.videoGallery.slides.2.alt'),
          text: this.$t('homePage.videoGallery.slides.2.text')
        }
      ]
    },

    selectedVideo() {
      return this.videos[this.selectedIndex]
    },

    modalOptions() {
      const video = this.selectedVideo
      return {
        autoplay: true,
        controls: true,
        muted: false,
        width,
        height: width * ratio,
        source: video.source
      }
    },

    sliderParams() {
      return {
        autoHeight: true,
        slidesPerView: 'auto',
        spaceBetween: 14,
        slideToClickedSlide: true,
        allowTouchMove: this.$device.isMobileOrTablet,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          init: () => {
            if (window.lazyUpdate) window.lazyUpdate()
          }
        }
      }
    }
  },

  beforeDestroy() {
    this.$root.$off('modal-closed', this.handleModalClosed)
  },

  mounted() {
    this.$root.$on('modal-closed', this.handleModalClosed)
  },

  methods: {
    videoBgLoaded() {
      this.showPoster = false
    },

    onSlideClick(index) {
      this.selectedIndex = index
    },

    toggleVideo(state) {
      const video = document.querySelector(
        `${this.isMobile ? '.bg-video-mobile' : '.bg-video-desktop'} video`
      )
      video?.[state]()
    },

    async initSwiper(isVisible) {
      if (isVisible) {
        this.swiper = await this.$swiper.insert(
          this.$refs['mining-slider'],
          this.sliderParams
        )
        this.swiper.snapGrid = [...this.swiper.slidesGrid]
        this.swiper.update()
      }
    },

    /**
     * Avoid an excessive DOM size
     */
    onVideoPlayHandler(index) {
      if (this.$refs.backgroundVideo || this.$refs.backgroundVideoMobile) {
        this.toggleVideo('pause')
      }

      this.selectedIndex = index
      this.showVideoModal = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$root.$emit('mining-video')
        }, 100)
      })

      this.swiper.update()
    },

    handleModalClosed(payload) {
      if (this.showVideoModal && payload.modalName === 'mining-video') {
        this.toggleVideo('play')
        this.showVideoModal = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.mining
  position: relative
  padding: 100px 0
  height: 1037px
  overflow: hidden
  +until-widescreen
    height: 900px
  +touch
    padding: 80px 0
    height: 768px
  +mobile
    height: unset
    padding-top: 0
    padding-bottom: 60px
  &__learn-more
    max-width: 150px
    width: 100%
    margin-left: auto
    margin-right: auto
  &__poster
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)
    max-width: 1920px
    width: 100%
    height: 100%
    +mobile
      position: initial
      transform: none
    ::v-deep
      picture
        width: 100%
        height: 100%
      img
        width: 100%
        height: 100%
        object-fit: cover
        object-position: center
  &__bg-video
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)
    max-width: 1920px
    width: 100%
    height: 100%
    padding: 0
    ::v-deep
      video
        width: 100%
        height: 100%
        object-fit: cover
        object-position: center
  &-slider
    width: 50%
    +until-widescreen
      max-height: unset
      height: auto
    +mobile
      width: 100%
  .container
    height: 100%
    align-items: center
    +touch
      padding: 0 20px!important
    +mobile
      flex-direction: column
      align-items: flex-start
      padding: 0!important
  &__info
    text-align: center
  &__subtitle
    font-size: 12px
    line-height: 24px
    margin-bottom: 20px
  &__title
    font-family: $lora
    font-size: 36px
    line-height: 42px
    margin-bottom: 20px
    text-transform: capitalize
    +touch
      font-size: 32px
  &__text
    font-size: 16px
    line-height: 24px
    margin-top: 20px
  .swiper-container__main
    max-width: 363px
    width: 100%
    +until-widescreen
      max-height: none
    .swiper-slide
      .mining-content
        position: relative
  .swiper-slide
    width: 170px!important
    text-align: center
    ::v-deep
      img
        width: 100%
        aspect-ratio: 170 / 105
        padding: 10px
  .slide-active
    ::v-deep
      img
        border: 1px solid $dark-border-color
  .mining-slider
    & > :first-child
      max-width: 656px
      width: 100%
      margin: 0 auto
      background: #fff
      padding: 79px 45px
      height: 100%
      display: flex
      flex-direction: column
      gap: 40px
      +touch
        padding: 40px 20px
        max-width: 354px
        margin: 0
      +mobile
        max-width: 100%
        width: 100%
        padding: 40px
      & > :nth-child(2)
        position: relative
        width: 100%
        padding-left: 19px
        padding-right: 19px
        +mobile
          padding-left: 9px
          padding-right: 9px
          max-width: 375px
          margin: 0 auto
  &-content
    cursor: pointer
    background-color: #fff
    height: 100%
    width: 100%
    justify-content: center
    align-items: center
    &__image
      position: relative

  &-info-text
    font-size: 16px
    line-height: 19px
    font-weight: bold
    margin-top: 17px
    text-transform: uppercase

  &-video
    justify-content: center
    align-items: center
    width: 50%
    +mobile
      width: 100%
      height: 375px
    ::v-deep
      .video-wrapper
        width: 100%
        height: 100%
        padding: 0
      video
        width: 100%
        height: 100%
        object-fit: cover
        object-position: center
  &__button-play
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 50px
    height: 50px
    ::v-deep
      img
        width: 50px!important
        height: 50px!important
        border: none!important
        padding: 0!important
.swiper-button-next
  right: 0px
  +mobile
    right: -9px
  &::after
    content: none
.swiper-button-prev
  left: 0px
  +mobile
    left: -9px
  &::after
    content: none
  svg
    transform: rotate(180deg)
.swiper-button-next,
.swiper-button-prev
  width: 40px
  height: 40px
  display: flex
  align-items: center
  justify-content: center
  background: #fff
  box-shadow: 0 1px 8px #00000029
  border-radius: 50%
  top: 48%
  transform: translateY(-50%)
  +touch
    top: 46%
  svg
    width: 24px
    height: 24px
</style>
